<template>
  <header>
    <div class="logo"><img src="@/assets/images/logo.svg" alt="логотип" /></div>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 55px;
  margin-bottom: 65px;

  @media screen and (max-width: 640px) {
    padding-top: 25px;
    margin-bottom: 30px;
  }
}
</style>
